import React, {useEffect, useRef, useState} from 'react'
import { Typography, Avatar, Input } from 'antd'
import { 
    ToTopOutlined, 
    UserOutlined, 
    ArrowRightOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import ActiveSider from '../../Assets/Images/Home/active_sider.png'
import DisabledSider from '../../Assets/Images/Home/disabled_sider.png'
import InactiveSider from '../../Assets/Images/Home/inactive_sider.png'

const SideMenu = (props) => {

    const usersCurrentView = props.usersCurrentView
    let usersAllConnected = props.usersAllConnected
    usersAllConnected = usersAllConnected.length > 0 ? usersAllConnected.filter(user => user.usuusuario != "rosmar.c.requena@kcc.com") : []

    const { Title } = Typography
    const [activeSide, setActiveSide] = useState(false)
    const [activeSubitemSide, setActiveSubitemSide] = useState(null)
    const [activeIconSide, setActiveIconSide] = useState('Inactive-Icon-Side')
    const [filterUserOnline, setFilterUserOnline] = useState('')
    const [filterUserOnlinePage, setFilterUserOnlinePage] = useState('')
    const [filterUserSameView, setFilterUserSameView] = useState('')

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const sidebarRef = useRef(null)

    useEffect(() => {
        const inactiveSide = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setActiveSide(false)
            }
        }

        document.addEventListener('mousedown', inactiveSide)
        return () => {
            document.removeEventListener('mousedown', inactiveSide)
        }
    }, [])

    return (
        <div 
            className={`Container-Side ${activeSide ? 'Active-Side' : ''}`} 
            ref={sidebarRef}
            onMouseOver={() => setActiveIconSide('Active-Icon-Side')}
            onMouseLeave={() => setActiveIconSide('Inactive-Icon-Side')}
        >
            <div className="Item-Icon-Side"></div>
            <div className="Container-Title-Side">
                <Title 
                    className="Title-Side" 
                    level={5}
                >
                    Estado de usuarios
                </Title>
                <ToTopOutlined 
                    className={`Icon-Title-Side ${activeSide ? 'Active-Icon' : `${activeIconSide}`}`} 
                    onClick={() => setActiveSide(!activeSide)} 
                />
            </div>
            <div className="Container-Card-Side">
                <div 
                    className={`Card-Item-Side ${activeSubitemSide == "subitem-3" ? "Active-Hover" : activeSubitemSide == null ? "" : "Inactive-Hover"}`} 
                >
                    <div 
                        style={{position: 'absolute', width: '100%', height: '100%'}} 
                        onClick={() => {
                            if(activeSubitemSide == "subitem-3"){
                                setActiveSubitemSide(null)
                            }else{
                                setActiveSubitemSide("subitem-3")
                            }
                        }}
                    ></div>
                    <p className="Text-Item-Side">Usuarios online</p>
                    <ArrowRightOutlined className="Icon-Card-Side" />
                    <div className="Card-Hover-Side"></div>
                    <div className="Container-Hover-Subitem-Side">
                        <div className="Container-Card-Subitem-Side">
                            <div className="Container-Title-Subitem-Side">
                                <Title 
                                    className="Title-Subitem-Side" 
                                    level={5}
                                >
                                    Usuarios online
                                </Title>
                                <ToTopOutlined className="Icon-Subitem-Side" />
                            </div>

                            <div className="Container-AllUser-Subitem-Side">
                                <div className="AllUser-Text-Subitem-Side">
                                    <UserOutlined className="Icon-AllUser-Subitem-Side" />
                                    Total usuarios
                                </div>
                                <div className="Count-AllUser-Subitem-Side">
                                    {usersAllConnected.length < 9 ? "0"+(usersAllConnected.filter((usu) => usu.usuactividad != 'desconectado').length)  : usersAllConnected.filter((usu) => usu.usuactividad != 'desconectado').length} usuarios
                                </div>
                            </div>

                            <div className="Container-Search-AllUser-Subitem-Side">
                                <div className="AllUser-Text-Subitem-Side">
                                    <Input
                                        className="Count-AllUser-Subitem-Side"
                                        bordered={false}
                                        onChange={(e) => setFilterUserOnline(e.target.value)}
                                        placeholder={`Buscar usuario`}
                                    />
                                </div>
                            </div>
                            <div className="Container-Subitem-Side">
                                {
                                    usersAllConnected
                                    .filter(usu => usu.usuusuario !== null)
                                    .filter(usu => usu.usuusuario.toLowerCase().includes(filterUserOnline.toLowerCase()))
                                    .map((user, key) => 
                                        <div className="Container-List-Subitem-Side" key={key}>
                                            <div className="Card-Subitem-Side">
                                                <div className="Container-Text-Subitem-Side">
                                                    <Avatar
                                                        className="Container-Icon-Subitem-Side"
                                                        size={26}
                                                        icon={<UserOutlined className="Icon-User-Subitem-Side" />}
                                                    />
                                                    <p>{user.usuusuario}</p>
                                                </div>
                                                <img
                                                    className='Menu-User-Icon'
                                                    src={
                                                        user.usuactividad == 'conectado' 
                                                        ? ActiveSider 
                                                        : user.usuactividad == 'ausente'
                                                            ? InactiveSider
                                                            : DisabledSider
                                                    }
                                                />
                                            </div>
                                        </div>    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div 
                    className={`Card-Item-Side ${activeSubitemSide == "subitem-1" ? "Active-Hover" : activeSubitemSide == null ? "" : "Inactive-Hover"}`} 
                > */}
                    {/* <div 
                        style={{position: 'absolute', width: '100%', height: '100%'}} 
                        onClick={() => {
                            if(activeSubitemSide == "subitem-1"){
                                setActiveSubitemSide(null)
                            }else{
                                setActiveSubitemSide("subitem-1")
                            }
                        }}
                    ></div> */}
                    {/* <p className="Text-Item-Side">Usuarios en vista actual</p>
                    <ArrowRightOutlined className="Icon-Card-Side" />
                    <div className="Card-Hover-Side"></div>
                    <div className="Container-Hover-Subitem-Side">
                        <div className="Container-Card-Subitem-Side">
                            <div className="Container-Title-Subitem-Side">
                                <Title 
                                    className="Title-Subitem-Side" 
                                    level={5}
                                >
                                    Usuarios en vista actual
                                </Title>
                                <ToTopOutlined className="Icon-Subitem-Side" />
                            </div>

                            <div className="Container-AllUser-Subitem-Side">
                                <div className="AllUser-Text-Subitem-Side">
                                    <UserOutlined className="Icon-AllUser-Subitem-Side" />
                                    Total usuarios
                                </div>
                                <div className="Count-AllUser-Subitem-Side">
                                    {usersCurrentView.length < 9 ? "0"+(usersCurrentView.filter((usu) => usu.usuactividad != 'desconectado').length)  : usersCurrentView.filter((usu) => usu.usuactividad != 'desconectado').length} usuarios
                                </div>
                            </div>

                            <div className="Container-Search-AllUser-Subitem-Side">
                                <div className="AllUser-Text-Subitem-Side">
                                    <Input
                                        className="Count-AllUser-Subitem-Side"
                                        bordered={false}
                                        onChange={(e) => setFilterUserOnlinePage(e.target.value)}
                                        placeholder={`Buscar usuario`}
                                    />
                                </div>
                            </div>
                            <div className="Container-Subitem-Side">
                            {
                                usersAllConnected
                                .filter(usu => usu.usuusuario !== null)
                                .filter(usu => usu.usuusuario.toLowerCase().includes(filterUserOnlinePage.toLowerCase()))
                                .map((user, key) =>
                                    <div className="Container-List-Subitem-Side" key={key}>
                                        <div className="Card-Subitem-Side">
                                            <div className="Container-Text-Subitem-Side">
                                                <Avatar
                                                    className="Container-Icon-Subitem-Side"
                                                    size={26}
                                                    icon={<UserOutlined className="Icon-User-Subitem-Side" />}
                                                />
                                                <p>{user.usuusuario}</p>
                                            </div>
                                            <img 
                                                className='Menu-User-Icon' 
                                                src={
                                                    user.usuactividad == 'conectado' 
                                                    ? ActiveSider 
                                                    : user.usuactividad == 'ausente'
                                                        ? InactiveSider
                                                        : DisabledSider
                                                }
                                            />
                                        </div>
                                    </div>  
                                )
                            }
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}
                {/* <div 
                    className={`Card-Item-Side ${activeSubitemSide == "subitem-2" ? "Active-Hover" : activeSubitemSide == null ? "" : "Inactive-Hover"}`}
                >
                    <div 
                        style={{position: 'absolute', width: '100%', height: '100%'}} 
                        onClick={() => {
                            if(activeSubitemSide == "subitem-2"){
                                setActiveSubitemSide(null)
                            }else{
                                setActiveSubitemSide("subitem-2")
                            }
                        }}
                    ></div>
                    <p className="Text-Item-Side">Actividad de usuarios</p>
                    <ArrowRightOutlined className="Icon-Card-Side" />
                    <div className="Card-Hover-Side"></div>
                    <div className="Container-Hover-Subitem-Side">
                        <div className="Container-Card-Subitem-Side">
                            <div className="Container-Title-Subitem-Side">
                                <Title 
                                    className="Title-Subitem-Side" 
                                    level={5}
                                >
                                    Actividad de usuarios
                                </Title>
                                <ToTopOutlined className="Icon-Subitem-Side" />
                            </div>
                            <div className="Container-AllUser-Subitem-Side">
                                <div  className="AllUser-Text-Subitem-Side">
                                    <UserOutlined className="Icon-AllUser-Subitem-Side" />
                                    Total usuarios
                                </div>
                                <div className="Count-AllUser-Subitem-Side">
                                    {usersAllConnected.length < 9 ? "0"+(usersAllConnected.filter((usu) => usu.usuactividad != 'desconectado' && usu.userfake == false).length)  : usersAllConnected.filter((usu) => usu.usuactividad != 'desconectado' && usu.userfake == false).length} usuarios
                                </div>
                            </div>
                            <div className="Container-Search-AllUser-Subitem-Side">
                                <div className="AllUser-Text-Subitem-Side">
                                    <Input
                                        className="Count-AllUser-Subitem-Side"
                                        bordered={false}
                                        onChange={(e) => setFilterUserSameView(e.target.value)}
                                        placeholder={`Buscar usuario`}
                                    />
                                </div>
                            </div>
                            <div className="Container-Subitem-Side">
                            {
                                usersAllConnected
                                .filter(usu => usu.usuusuario !== null)
                                .filter(usu => 
                                    (usu.usuusuario?.toLowerCase().includes(filterUserSameView.toLowerCase()))
                                    // (usu.userfake == false)
                                    // && (usu.usuusuario?.toLowerCase().includes(filterUserSameView.toLowerCase()))
                                )
                                .map((user, key) => 
                                    <div className="Container-List-Subitem-Side" key={key}>
                                        <div className="Card-Subitem-Side">
                                            <div className="Container-Text-Subitem-Side">
                                                <Avatar
                                                    className="Container-Icon-Subitem-Side"
                                                    size={26}
                                                    icon={<UserOutlined className="Icon-User-Subitem-Side" />}
                                                />
                                                <p>{user.usuusuario}</p>
                                            </div>
                                            <img 
                                                className='Menu-User-Icon'
                                                src={
                                                    user.usuactividad == 'conectado' 
                                                    ? ActiveSider 
                                                    : user.usuactividad == 'ausente'
                                                        ? InactiveSider
                                                        : DisabledSider
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="Container-Hover-Side-User">
                {
                    usersCurrentView
                    .filter(usu => 
                        usu.usucorreo != rex_data_user_logingrow.usucorreo && usu.usuactividad != 'desconectado'
                    ).map((user, indexUser) => 
                        <div style={{position: 'relative'}}>
                            <Avatar
                                className="Container-Icon-Subitem-Side"
                                size={20}
                                icon={<UserOutlined className="Icon-User-Subitem-Side" />}
                                key={indexUser}
                            />
                            <div className="Item-Icon-Side">
                                <div className={`Circle-Icon-Side ${user.usuactividad == 'conectado' ? 'Active-User-Side' : user.usuactividad == 'ausente' ? 'Inactive-User-Side' : 'Disable-User-Side'}`}></div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
        // <></>
    )
}

export default SideMenu